




























































































































import Base from '@/mixins/Base.vue';
import { IProject, IProjectResponse } from '@/interfaces/project';
import plotter from './partials/Plotter.vue';

const component = Base.extend({
    components: {
        plotter,
    },
    data() {
        return {
            errors: false,
            activeTab: 'general',
            project: undefined as any | IProject,

        };
    },
    mounted() {
        this.getProject();
    },
    methods: {

        getProject(): void {
            this.get<IProjectResponse>(`projects/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.project = data.data;
                });
        },
        saveProject(): void {
            this.put<IProjectResponse>(`projects/${this.$route.params.id}`, {
                ...this.project,
                country_id: this.project.country ? this.project.country.id : null,
                region_id: this.project.region ? this.project.region.id : null,
            }).then(({ data }) => {
                this.project = data.data;
                this.$emit('success', { message: `project ${data.data.name} updated` });
            });
        },

    },
});

export default component;
