










































































































import Base from '@/mixins/Base.vue';
import { ILocation } from '@/interfaces/location';
import { IService } from '@/interfaces/service';

const component: any = Base.extend({
    name: 'LocationItem',
    components: {

        LocationItem: () => import('./LocationItem.vue'),
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        children: {
            type: Array as () => Array<IService>,
            default() {
                return [];
            },
        },
        parent: {
            type: Object,
            default: null,
        },
    },
    methods: {
        toggle(location: ILocation): void {
            const loc = location;
            loc.expanded = !loc.expanded;
            this.$forceUpdate();
        },
        select(location: ILocation): void {
            const loc = location;
            loc.expanded = true;
            this.$emit('input', loc);
        },
        order(locations: Array<ILocation>): Array<ILocation> {
            const loc: Array<ILocation> = locations;
            return loc.slice().sort((a, b) => a.position - b.position);
        },
    },
});
export default component;
