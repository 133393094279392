






















































































































































































































































































































































































































































































































































































































































































































































































































import interact from 'interactjs';
import Base from '@/mixins/Base.vue';
import { ISpObject, ISpObjectResponse } from '@/interfaces/spobject';
import { ILocation, ILocationResponse, ILocationsResponse } from '@/interfaces/location';
import { IProject } from '@/interfaces/project';
import LocationItem from './LocationItem.vue';

const component = Base.extend({
    components: {
        LocationItem,
    },
    props: {
        project: {
            type: Object as () => IProject,
            default: null,
        },

    },
    mounted() {
        this.getLocations();

        interact('.item-object').draggable({
            listeners: {
                move: this.onInteractDrag,
                end: this.onInteractEnd,
            },
        });
        interact('.item-location').draggable({
            listeners: {
                move: this.onInteractDrag,
                end: this.onInteractEnd,
            },
        })
            .resizable({
                edges: {
                    top: true, left: true, bottom: true, right: true,
                },
                listeners: {
                    move: this.onInteractResize,
                    end: this.onInteractEnd,
                },
            });

        if (this.project.address) {
            this.newObject.phone = this.project.phone;
            this.newObject.address = {
                type: 'location',
                street: this.project.address.street,
                number: this.project.address.number,
                box: this.project.address.box,
                postal_code: this.project.address.postal_code,
                city: this.project.address.city,
                country: this.project.address.country,
                region: this.project.address.region,
                country_id: this.project.address.country_id,
                region_id: this.project.address.region_id,
                lat: this.project.address.lat,
                lng: this.project.address.lng,
            };
        }
    },
    data() {
        return {
            plan: null,
            isMenuClosed: false,
            isSaving: false,
            isAddCodeModalActive: false,
            isAddLocationModalActive: false,
            isAddObjectsModalActive: false,
            isCreateObjectsModalActive: false,
            isEditLocationModalActive: false,
            draggedObject: undefined as (any | ISpObject | ILocation),
            linkObjects: [] as Array<ISpObject>,
            locations: [] as Array<ILocation>,
            newLocation: {} as ILocation,
            newObject: {} as ISpObject,
            selectedLocation: undefined as (any | ILocation),
            selectedObject: undefined as (any | ISpObject),
        };
    },
    methods: {
        onEnd(): void {
            this.isSaving = true;
            this.put<ILocationResponse>(`projects/${this.$route.params.id}/locations/${this.locations[0].id}`, {
                ...this.locations[0],
            })
                .finally(() => { this.isSaving = false; });
        },
        onMouseDown(object: ISpObject | Location): void {
            this.draggedObject = object;
        },
        onMouseUp(): void {
            this.draggedObject = undefined;
        },
        onInteractResize(event: any): void {
            if (this.draggedObject) {
                const ratioWidth = 100 / (this.$refs.plotter as HTMLElement).clientWidth;
                const ratioHeight = 100 / (this.$refs.plotter as HTMLElement).clientHeight;

                this.draggedObject.position_x += event.deltaRect.left * ratioWidth;
                this.draggedObject.position_y += event.deltaRect.top * ratioHeight;
                this.draggedObject.width += event.deltaRect.width * ratioWidth;
                this.draggedObject.height += event.deltaRect.height * ratioHeight;

                if (this.draggedObject.width < 1) this.draggedObject.width = 1;
                if (this.draggedObject.height < 1) this.draggedObject.height = 1;
                this.$forceUpdate();
            }
        },
        onInteractDrag(event: any): void {
            if (this.draggedObject) {
                const ratioWidth = 100 / (this.$refs.plotter as HTMLElement).clientWidth;
                const ratioHeight = 100 / (this.$refs.plotter as HTMLElement).clientHeight;
                this.draggedObject.position_x += event.delta.x * ratioWidth;
                this.draggedObject.position_y += event.delta.y * ratioHeight;
                if (this.draggedObject.position_x < 0) this.draggedObject.position_x = 0;
                if (this.draggedObject.position_x > 100 - (this.draggedObject.width || 0)) this.draggedObject.position_x = 100 - (this.draggedObject.width || 0);
                if (this.draggedObject.position_y < 0) this.draggedObject.position_y = 0;
                if (this.draggedObject.position_y > 100 - (this.draggedObject.height || 0)) this.draggedObject.position_y = 100 - (this.draggedObject.height || 0);
                this.$forceUpdate();
            }
        },
        onInteractEnd(): void {
            this.put<ILocationResponse>(`projects/${this.$route.params.id}/locations/${this.selectedLocation.id}`, {
                ...this.selectedLocation,
            });
        },
        getLocations(): void {
            this.get<ILocationsResponse>(`projects/${this.$route.params.id}/locations`).then(({ data }) => {
                this.locations = data.data;
                if (this.locations[0]) this.getLocation(this.locations[0].id);
            });
        },

        getLocation(id: number): void {
            this.plan = null;

            this.get<ILocationResponse>(`projects/${this.$route.params.id}/locations/${id}`)
                .then(({ data }) => {
                    this.selectedLocation = data.data;
                });
        },
        refreshLocation(): void {
            if (this.selectedLocation) this.getLocation(this.selectedLocation.id);
        },
        saveLocation(): void {
            this.isSaving = true;
            this.put<ILocationResponse>(`projects/${this.$route.params.id}/locations/${this.selectedLocation.id}`, {
                ...this.selectedLocation,
            })
                .then(() => {
                    this.$emit('success', { message: 'location saved', duration: 1000 });
                    this.isEditLocationModalActive = false;
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        uploadLocationPlan(): void {
            const formData = new FormData();

            if (this.plan) {
                formData.append('plan', this.plan as any);
            }

            this.post<ILocationResponse>(`projects/${this.$route.params.id}/locations/${this.selectedLocation.id}/plan`, formData)
                .then(({ data }) => {
                    this.$emit('success', { message: 'location plan updated' });

                    this.selectedLocation.plan = data.data.plan;
                    this.isEditLocationModalActive = false;
                })
                .finally(() => {
                    this.plan = null;
                });
        },
        addLocation(): void {
            this.post<ILocationResponse>(`projects/${this.$route.params.id}/locations`, {
                name: this.newLocation.name,
                parent_id: this.newLocation.parent_id,
            }).then(({ data }) => {
                this.$emit('success', { message: 'location created' });

                if (!this.selectedLocation.children) this.selectedLocation.children = [];

                this.selectedLocation.children.push(data.data);
                this.isAddLocationModalActive = false;
            });
        },
        deleteLocation(location: ILocation): void {
            this.confirm().then(() => {
                this.delete(`projects/${this.$route.params.id}/locations/${location.id}`).then(() => {
                    this.$emit('success', { message: 'location deleted' });
                    this.selectedLocation = undefined;
                    this.getLocations();
                });
            });
        },

        saveObject(): void {
            this.post<ISpObjectResponse>('objects', {
                ...this.newObject,
                client_id: this.project.client_id,
                location_id: this.selectedLocation.id,
                project_id: this.project.id,
                country_id: this.newObject.address ? this.newObject.address.country_id : null,
                region_id: this.newObject.address ? this.newObject.address.region_id : null,
            })
                .then(({ data }) => {
                    this.$emit('success', { message: 'object created' });

                    if (!this.selectedLocation.objects) {
                        this.selectedLocation.objects = [];
                    }
                    this.selectedLocation.objects.push(data.data);
                    this.isCreateObjectsModalActive = false;
                    this.isAddObjectsModalActive = false;
                // this.refreshLocation()
                });
        },
        addObjects(): void {
            this.post<ILocationResponse>(`projects/${this.$route.params.id}/locations/${this.selectedLocation.id}/objects`, {
                objects: this.linkObjects.map((o) => o.id),
            })
                .then(({ data }) => {
                    this.$emit('success', { message: 'object created' });
                    this.selectedLocation.objects = data.data.objects;
                    this.isAddObjectsModalActive = false;
                    this.isCreateObjectsModalActive = false;
                });
        },
        saveSelectedObject(): void {
            this.isSaving = true;
            this.put<ISpObjectResponse>(`objects/${this.selectedObject.id}`, {
                ...this.selectedObject,
                country_id: this.selectedObject.country ? this.selectedObject.country.id : null,
                region_id: this.selectedObject.region ? this.selectedObject.region.id : null,
            })
                .then(() => {
                    this.$emit('success', { message: 'object updated' });
                    this.selectedObject = null;
                })
                .finally(() => { this.isSaving = false; });
        },
        removeSelectedObject(): void {
            const index = this.selectedLocation.objects.findIndex((o: ISpObject) => o.id === this.selectedObject.id);

            this.$delete(this.selectedLocation.objects, index);

            this.selectedObject = null;
        },
    },
});

export default component;
